import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import {
  loggedInUserGuard,
  dashboardPermissionsGuard,
} from '@ambassy/api-client';
import { updateIntercom } from '../intercom';
import WorkflowIndex from '../app/Pages/Workflow/Index.vue';
import WorkflowCreate from '../app/Pages/Workflow/Create.vue';

export const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/login',
    component: () => import('../app/Pages/Auth/Login.vue'),
    name: 'login',
  },
  {
    path: '/reg-25',
    component: () => import('../app/Pages/Auth/Register.vue'),
    name: 'register',
  },
  {
    path: '/forgot-password',
    component: () => import('../app/Pages/Auth/ForgotPassword.vue'),
    name: 'password.request',
  },
  {
    path: '/reset-password',
    component: () => import('../app/Pages/Auth/ResetPassword.vue'),
    name: 'password.reset',
  },
  {
    path: '/complete-registration',
    component: () => import('../app/Pages/Auth/CompleteInvite.vue'),
    name: 'password.complete-registration',
  },
  {
    path: '',
    beforeEnter: [loggedInUserGuard, dashboardPermissionsGuard, updateIntercom],
    children: [
      {
        path: '',
        component: () => import('../app/Pages/Dashboard.vue'),
        name: 'dashboard',
      },
      {
        path: '/campaigns',
        component: () => import('../app/Pages/Campaigns/Index.vue'),
        name: 'campaigns.index',
      },
      {
        path: '/campaigns/create',
        component: () => import('../app/Pages/Campaigns/Create.vue'),
        name: 'campaigns.create',
      },
      {
        path: '/quests/catalog',
        component: () => import('../app/Pages/Quest/Catalog/Catalog.vue'),
        name: 'quests.catalog',
      },
      {
        path: '/quests',
        component: () => import('../app/Pages/Quest/Index.vue'),
        name: 'quests.index',
      },
      {
        path: '/quests/ticket-sell/:questId/show',
        component: () => import('../app/Pages/Quest/TicketSell/Detail.vue'),
        name: 'quests.ticket-sell.show',
      },
      {
        path: '/quests/ticket-sell/:ticketServiceProvider/create',
        component: () => import('../app/Pages/Quest/TicketSell/Create.vue'),
        name: 'quests.ticket-sell.create',
      },
      {
        path: '/quests/general/create',
        component: () => import('../app/Pages/Quest/General/CreatePage.vue'),
        name: 'quests.general.create',
      },
      {
        path: '/quests/general/:questId/show',
        component: () => import('../app/Pages/Quest/General/Detail.vue'),
        name: 'quests.general.show',
      },
      {
        path: '/quests/user-generated-content/create',
        component: () =>
          import('../app/Pages/Quest/UserGeneratedContent/Create.vue'),
        name: 'quests.user-generated-content.create',
      },
      {
        path: '/quests/user-generated-content/:questId/show',
        component: () =>
          import('../app/Pages/Quest/UserGeneratedContent/Detail.vue'),
        name: 'quests.user-generated-content.show',
      },
      {
        path: '/quests/review',
        component: () => import('../app/Pages/Review/Index.vue'),
        name: 'quests.review.index',
      },
      {
        path: '/integrations',
        component: () => import('../app/Pages/Integration/Index.vue'),
        name: 'integrations.index',
      },
      {
        path: '/integrations/ytp',
        component: () =>
          import('../app/Pages/Integration/YourTicketProvider/Create.vue'),
        name: 'integrations.ytp.create',
      },
      {
        path: '/integrations/ihgat',
        component: () =>
          import('../app/Pages/Integration/IHaveGotATicket/Create.vue'),
        name: 'integrations.ihgat.create',
      },
      {
        path: '/integrations/eventix',
        component: () => import('../app/Pages/Integration/Eventix/Create.vue'),
        name: 'integrations.eventix.create',
      },
      {
        path: '/integrations/universe',
        component: () => import('../app/Pages/Integration/Universe/Create.vue'),
        name: 'integrations.universe.create',
      },
      {
        path: '/integrations/woo-commerce',
        component: () =>
          import('../app/Pages/Integration/WooCommerce/Create.vue'),
        name: 'integrations.woocommerce.create',
      },
      {
        path: '/integrations/seetickets',
        component: () =>
          import('../app/Pages/Integration/SeeTickets/Create.vue'),
        name: 'integrations.seetickets.create',
      },
      {
        path: '/integrations/whatsapp',
        component: () => import('../app/Pages/Integration/WhatsApp/Create.vue'),
        name: 'integrations.whatsapp.create',
      },
      {
        path: '/integrations/whatsapp/phone',
        component: () => import('../app/Pages/Integration/WhatsApp/Phone.vue'),
        name: 'integration.whatsapp.select-phone-number',
      },
      {
        path: '/integrations/whatsapp/templates',
        component: () =>
          import('../app/Pages/Integration/WhatsApp/Templates.vue'),
        name: 'integration.whatsapp.templates',
      },
      {
        path: '/whatsapp-shops',
        component: () => import('../app/Pages/WhatsappShop/Index.vue'),
        name: 'whatsapp-shop.index',
      },
      {
        path: '/whatsapp-shop/:ticketServiceProvider/create',
        component: () => import('../app/Pages/WhatsappShop/Create.vue'),
        name: 'whatsapp-shop.create',
      },
      {
        path: '/contacts',
        component: () => import('../app/Pages/Contacts/Contacts.vue'),
        name: 'contacts.index',
      },
      {
        path: '/ambassadors',
        component: () => import('../app/Pages/Ambassador/Index.vue'),
        name: 'ambassadors.index',
      },
      {
        path: '/ambassadors/:ambassadorId/show',
        component: () => import('../app/Pages/Ambassador/Show.vue'),
        name: 'ambassadors.detail',
      },
      {
        path: '/landing-pages',
        component: () => import('../app/Pages/LandingPage/Index.vue'),
        name: 'ambassadors.landing',
      },
      {
        path: '/landing-page/create',
        component: () => import('../app/Pages/LandingPage/Create.vue'),
        name: 'landing.create',
      },
      {
        path: '/landing-page/fields',
        component: () => import('../app/Pages/LandingPage/Fields.vue'),
        name: 'landing.fields',
      },
      {
        path: '/landing-page/:landingPageId',
        component: () => import('../app/Pages/LandingPage/Show.vue'),
        name: 'landing.show',
      },
      {
        path: '/ambassadors/:ambassador/show',
        component: () => import('../app/Pages/Ambassador/Show.vue'),
        name: 'ambassadors.show',
      },
      {
        path: '/rewards',
        component: () => import('../app/Pages/Reward/Index.vue'),
        name: 'rewards.index',
      },
      {
        path: '/rewards/catalog',
        component: () => import('../app/Pages/Reward/Catalog/Catalog.vue'),
        name: 'rewards.catalog',
      },
      {
        path: '/rewards/create',
        component: () => import('../app/Pages/Reward/Create.vue'),
        name: 'reward-product.create',
      },
      {
        path: '/rewards/:rewardId/show',
        component: () => import('../app/Pages/Reward/Show.vue'),
        name: 'reward-product.edit',
      },
      {
        path: '/fulfillment',
        component: () => import('../app/Pages/Fulfillment/Layout.vue'),
        name: 'fulfillment.index',
      },
      {
        path: '/admin/finance',
        component: () => import('../app/Pages/Admin/Finance/Index.vue'),
        name: 'admin.finance.index',
      },
      {
        path: '/admin/settings/mail',
        component: () => import('../app/Pages/Admin/MailSettings/Settings.vue'),
        name: 'admin.settings.mail',
      },
      {
        path: '/admin/settings/reconciliation',
        component: () => import('../app/Pages/Admin/Reconciliation/Show.vue'),
        name: 'admin.settings.reconciliation',
      },
      {
        path: '/admin/settings/tools',
        component: () => import('../app/Pages/Admin/Tools/Settings.vue'),
        name: 'admin.settings.tools',
      },
      {
        path: '/admin/settings/whatsapp',
        component: () =>
          import('../app/Pages/Admin/WhatsappSettings/Settings.vue'),
        name: 'admin.settings.whatsapp',
      },
      {
        path: '/admin/settings/pre-register',
        component: () => import('../app/Pages/Admin/PreRegisters/Index.vue'),
        name: 'admin.settings.pre-register',
      },
      {
        path: '/profile',
        component: () => import('../app/Pages/Profile/Edit.vue'),
        name: 'profile.edit',
      },
      {
        path: '/profile/update',
        component: () => import('../app/Pages/Profile/Edit.vue'),
        name: 'profile.update',
      },
      {
        path: '/analytics',
        component: () => import('../app/Pages/Analytics/Index.vue'),
        name: 'analytics.index',
      },
      {
        path: '/settings',
        component: () => import('../app/Pages/Settings/Index.vue'),
        name: 'settings.index',
      },
      {
        path: '/settings/appearance',
        component: () =>
          import('../app/Pages/Settings/Appearance/Appearance.vue'),
        name: 'settings.appearance',
      },
      {
        path: '/settings/appearance/pages',
        component: () => import('../app/Pages/Settings/Appearance/Pages.vue'),
        name: 'settings.appearance.pages',
      },
      {
        path: '/settings/tags',
        component: () => import('../app/Pages/Settings/Tags/Index.vue'),
        name: 'settings.tags',
      },
      {
        path: '/settings/users',
        component: () => import('../app/Pages/Settings/Users.vue'),
        name: 'settings.users',
      },
      {
        path: '/settings/app-language',
        component: () => import('../app/Pages/Settings/AppLanguage.vue'),
        name: 'settings.app-language',
      },
      {
        path: '/settings/company',
        component: () => import('../app/Pages/Settings/CompanyDetails.vue'),
        name: 'settings.company',
      },
      {
        path: '/settings/whatsapp-templates',
        component: () => import('../app/Pages/Settings/WhatsappTemplates.vue'),
        name: 'settings.whatsapp-templates',
      },
      {
        path: '/settings/export',
        component: () => import('../app/Pages/Settings/Export.vue'),
        name: 'settings.export',
      },
      {
        path: '/settings/finance',
        component: () => import('../app/Pages/Settings/Finance.vue'),
        name: 'settings.finance',
      },
      {
        path: '/workflows',
        name: 'workflows.index',
        component: () => import('../app/Pages/Workflow/Index.vue'),
      },
      {
        path: '/workflows/create',
        name: 'workflows.create',
        component: () => import('../app/Pages/Workflow/Create.vue'),
      },
      {
        path: '/whatsapp-templates',
        name: 'whatsapp-templates.index',
        component: () => import('../app/Pages/WhatsappTemplates/Index.vue'),
      },
      {
        path: '/whatsapp-templates/create',
        name: 'whatsapp-templates.create',
        component: () => import('../app/Pages/WhatsappTemplates/Create.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../app/Pages/Error/404.vue'),
    name: 'not-found',
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});
