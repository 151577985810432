import { apiClient } from '../client/apiClient';
import { storageService } from '../storage';
import { FirebaseAuthResponse, UserOutput } from '@ambassy/schema';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { FirebaseOptions } from 'firebase/app';
import {
  FirebaseFirestore,
  FirebaseFirestorePlugin,
} from '@capacitor-firebase/firestore';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { authenticationService } from '../guard';

const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const FIREBASE_TOKEN_STORAGE_KEY = 'firebase_token';
export const FIREBASE_TOKEN_EXPIRES_AT_STORAGE_KEY =
  'firebase_token_expires_at';

export const authWithFirebase = async (): Promise<FirebaseAuthResponse> => {
  apiClient.authorize(await authenticationService.getToken());
  return apiClient.getWithError('/api/v1/me/firebase-auth');
};

export const authWithFirebaseFromStorage = async () => {
  let storedToken = await storageService.getItem(FIREBASE_TOKEN_STORAGE_KEY);
  await FirebaseAuthentication.signInWithCustomToken({
    token: storedToken || '',
  });
};

export const isFirebaseTokenExpired = async (): Promise<boolean> => {
  const expiresAt = await storageService.getItem(
    FIREBASE_TOKEN_EXPIRES_AT_STORAGE_KEY,
  );
  return (
    new Date(expiresAt || '1970-01-01').getTime() - new Date().getTime() < 60000
  );
};

export const setFirebaseToken = (token: FirebaseAuthResponse) => {
  storageService.setItem(FIREBASE_TOKEN_STORAGE_KEY, token.token);
  storageService.setItem(
    FIREBASE_TOKEN_EXPIRES_AT_STORAGE_KEY,
    token.expiresAt,
  );
  const auth = getAuth();
  signInWithCustomToken(auth, token.token);
};

const useFirebaseClient = () => {
  return FirebaseFirestore;
};

export const getAmbassadorPath = (user: UserOutput) => {
  return `/users/${user.ambassadorId}`;
};

export const getOrganisationPath = (id: string) => {
  return `/organisations/${id}`;
};

export const getThemePath = (organisationId: string) => {
  return getOrganisationPath(organisationId) + '/theme/default';
};

export const getHomePageTextPath = () => {
  return '/texts/homepage';
};

export const getQuestsPageTextPath = () => {
  return '/texts/quests';
};

export const AmbassadorCollections = {
  quests: 'quests',
};

export class FirestoreClient {
  constructor(private readonly db: FirebaseFirestorePlugin) {}

  public getDoc<T>(path: string, fallback?: () => Promise<T>): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        const document = await this.db.getDocument({
          reference: path,
        });
        if (!document) {
          fallback
            ? fallback().then(resolve).catch(reject)
            : reject('Not found');
        } else {
          resolve(document.snapshot.data as T);
        }
      } catch (error: any) {
        console.error(error);
        fallback
          ? fallback().then(resolve).catch(reject)
          : reject(error.message);
      }
    });
  }
}

export const useFirestoreClient = () => {
  return new FirestoreClient(useFirebaseClient());
};
