import { defineStore } from 'pinia';
import { AmbassadorRewardsOutput, ClientErrorInterface } from '@ambassy/schema';

export const useRewardClaimRewardStore = defineStore('rewardClaimRewardStore', {
  state: () => ({
    isLoading: true,
    errorMessage: '',
    reward: {} as AmbassadorRewardsOutput[number],
  }),
  actions: {
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    clearError() {
      this.errorMessage = '';
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
    setReward(reward: AmbassadorRewardsOutput[number]) {
      this.reward = reward;
    },
  },
});
