import { defineStore } from 'pinia';
import {
  ApiError,
  AmbassadorClaimInstructionsOutput,
  ClientErrorInterface,
} from '@ambassy/schema';

export const useRewardClaimStore = defineStore('rewardClaimStore', {
  state: () => ({
    isLoading: true,
    errors: [] as ApiError<AmbassadorClaimInstructionsOutput['fields']>,
    claimInfo: {} as Omit<AmbassadorClaimInstructionsOutput, 'fields'> & {
      fields: AmbassadorClaimInstructionsOutput['fields'] &
        { value: string | null }[];
    },
  }),
  actions: {
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    clearError() {
      this.errors = [];
    },
    setError(
      err: ClientErrorInterface<AmbassadorClaimInstructionsOutput['fields']>,
    ) {
      this.errors = err.getErrors();
    },
    setClaimInfo(claimInfo: AmbassadorClaimInstructionsOutput) {
      this.claimInfo = {
        ...claimInfo,
        fields: claimInfo.fields.map((field) => ({ ...field, value: null })),
      };
    },
  },
});
