import { defineStore } from 'pinia';
import {
  AmbassadorRewardProductOutput,
  ClientErrorInterface,
} from '@ambassy/schema';

export const useConfirmClaimRewardProductStore = defineStore(
  'confirmClaimRewardProductStore',
  {
    state: () => ({
      rewardProduct: null as AmbassadorRewardProductOutput | null,
      errorMessage: '',
      isLoading: false,
    }),
    actions: {
      setRewardProduct(rewardProduct: AmbassadorRewardProductOutput) {
        this.rewardProduct = rewardProduct;
      },
      startLoading() {
        this.isLoading = true;
      },
      stopLoading() {
        this.isLoading = false;
      },
      setError(err: ClientErrorInterface<any>) {
        this.errorMessage = err.message;
      },
    },
  },
);
