import type { GoalOutput } from '@ambassy/schema';
import { defineStore } from 'pinia';

export const useGoalStore = defineStore('goal', {
  state: () => ({
    goal: null as GoalOutput | null,
    isLoading: true,
    error: null,
  }),
  actions: {
    setGoal(goal: GoalOutput | null) {
      this.goal = goal;
    },
    stopLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    },
  },
});
