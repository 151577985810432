import { defineStore } from 'pinia';
import { ClientErrorInterface, QuestDiscountsResponse } from '@ambassy/schema';

export const useDiscountStore = defineStore('discounts', {
  state: () => ({
    discounts: {} as QuestDiscountsResponse,
    isLoading: true,
    errorMessage: '',
  }),
  actions: {
    setDiscounts(discounts: QuestDiscountsResponse) {
      this.discounts = discounts;
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
    stopLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    },
  },
});
