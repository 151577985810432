import { defineStore } from 'pinia';
import { GoalOutput } from '@ambassy/schema';

export const useRewardDetailCardGoalStore = defineStore(
  'rewardDetailCardGoalStore',
  {
    state: () => ({
      goal: {} as GoalOutput,
      isLoading: true,
      errorMessage: '',
    }),
    actions: {
      startLoading() {
        this.isLoading = true;
      },
      stopLoading() {
        this.isLoading = false;
      },
      clearErrorMessage() {
        this.errorMessage = '';
      },
      setGoal(goal: GoalOutput) {
        this.goal = goal;
      },
      setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage;
      },
    },
  },
);
