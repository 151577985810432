import { defineStore } from 'pinia';
import { ClientErrorInterface, UserOutput } from '@ambassy/schema';
import { fa } from '@faker-js/faker';

export const useAmbassadorSettingsStore = defineStore(
  'ambassador-settings-menu',
  {
    state: () => ({
      isLoading: true,
      errorMessage: '',
      user: {} as UserOutput,
    }),
    actions: {
      startLoading() {
        this.isLoading = true;
      },
      stopLoading() {
        this.isLoading = false;
      },
      clearError() {
        this.errorMessage = '';
      },
      setError(error: ClientErrorInterface<any>) {
        this.errorMessage = error.message;
      },
      setUser(stats: UserOutput) {
        this.user = stats;
      },
    },
  },
);
